<template>
  <div class="base-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <h4>基础信息</h4>
      <el-form-item label="券名称：" prop="name">
        <el-input
          placeholder="请输入优惠券名称"
          v-model="ruleForm.name"
        />
      </el-form-item>

      <el-form-item label="优惠金额：" prop="discount" v-if="couponType==1">
        <el-input-number v-model="ruleForm.discount" :min="0" :precision="2" :controls="false" style="margin-right:10px" disabled />元
      </el-form-item>
      <el-form-item label="优惠折扣：" prop="discount" v-else-if="couponType==2">
        <el-input-number v-model="ruleForm.discount" :min="0" :precision="2" :max="10" :controls="false" style="margin-right:10px" disabled/>折
      </el-form-item>

      <el-form-item label="发放方式：" prop="distributionType" required v-if="couponType==1">
        <el-radio-group v-model="ruleForm.distributionType" class="ml-4" disabled>
          <el-radio :label="1" size="large">免费</el-radio>
          <el-radio :label="2" size="large">付费</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="实收：" prop="salePrice" v-if="ruleForm.distributionType==2">
        <el-input-number v-model="ruleForm.salePrice" :min="0" :precision="2" :controls="false" style="margin-right:10px" disabled />元
      </el-form-item>

      <el-form-item :label="ruleForm.distributionType==1?'发放时间：':'售卖时间：'" prop="saleDate">
        <el-date-picker
          v-model="ruleForm.saleDate"
          type="daterange"
          range-separator="到"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD HH:mm:ss"
          @change="handleSaleDateChange"
          disabled
        />
      </el-form-item>

      <el-form-item :label="ruleForm.distributionType==2?'售卖数量：':'发放数量：'" prop="total">
        <el-input-number v-model="ruleForm.total" :min="ruleForm.total" :precision="0" :controls="false" style="margin-right:10px" />张
      </el-form-item>
      <el-form-item label="展示渠道：" prop="isLimit" required style="margin: 0">
        <el-radio-group v-model="ruleForm.isLimit" class="ml-4" style="height: 20px">
          <el-radio :label="false" size="large" style="width: 100%">不限制</el-radio>
          <el-radio :label="true" size="large" style="height: 20px">指定渠道不可用
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="limitApplet" required style="margin: 0 30px;">
        <el-checkbox v-model="ruleForm.limitApplet" :label="true" size="large" :disabled="!ruleForm.isLimit">小程序内</el-checkbox>
      </el-form-item>
      <h4>使用规则</h4>
      <el-form-item label="使用门槛：" prop="hasThreshold" v-if="couponType!=3">
        <el-radio-group v-model="ruleForm.hasThreshold" disabled>
          <el-radio :label="false">无门槛</el-radio>
          <el-radio :label="true">
            <div class="menkan-form radio-input">
              <el-form-item prop="thresholdPrice">
                <span>订单满</span>
                <el-input
                  :disabled="!ruleForm.hasThreshold"
                  v-model="ruleForm.thresholdPrice"
                />
                <span>元</span>
              </el-form-item>
            </div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="可用项目：" prop="applicableScope" required>
        <el-radio-group v-model="ruleForm.applicableScope" class="ml-4" disabled>
          <el-radio :label="1" size="large" v-if="couponType!=3">全部服务</el-radio>
          <el-radio :label="2" size="large">指定服务</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.applicableScope==2" prop="serveIdList">
        <el-button size="small" @click="handleChangeServe" style="margin-right:10px" disabled>选择项目</el-button>
        <template :key="item" v-for="item in currentServeName">
          <el-tag size="small" style="margin-right:10px">{{ item }}</el-tag>
        </template>
      </el-form-item>

      <el-form-item label="券有效期：" prop="validityType">
        <div class="validity-period-wrap">
          <el-radio-group v-model="ruleForm.validityType">
            <el-radio :label="1">
              <div class="validity-period">
                <template v-if="ruleForm.validityType == 1">
                  <el-form-item prop="date">
                    <el-date-picker
                      @change="handleDateChange"
                      v-model="ruleForm.date"
                      type="datetimerange"
                      range-separator="到"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="YYYY-MM-DD HH:mm:ss"
                      :default-time="defaultTime"
                    />
                  </el-form-item>
                </template>
                <template v-else>
                  <el-date-picker
                    disabled
                    type="datetimerange"
                    range-separator="到"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    :default-time="defaultTime"
                  />
                </template>
              </div>
            </el-radio>
            <el-radio :label="2">
              <div class="validity-period radio-input">
                <el-form-item :prop="ruleForm.validityType == 2 ? 'days' : ''">
                  <span>领取当日开始</span>

                  <template v-if="ruleForm.validityType == 2">
                    <el-input v-model="ruleForm.days" />
                  </template>
                  <template v-else>
                    <el-input disabled />
                  </template>
                  <span>天内有效</span>
                </el-form-item>
              </div>
            </el-radio>
            <el-radio :label="3">
              <div class="validity-period radio-input">
                <el-form-item :prop="ruleForm.validityType == 3 ? 'days' : ''">
                  <span>领取次日开始</span>
                  <template v-if="ruleForm.validityType == 3">
                    <el-input v-model="ruleForm.days" />
                  </template>
                  <template v-else>
                    <el-input disabled />
                  </template>
                  <span>天内有效</span>
                </el-form-item>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <div v-if="couponType==1">
        <el-form-item label="生效周期：" prop="effectiveWeekType">
          <el-radio-group v-model="ruleForm.effectiveWeekType" class="ml-4" disabled>
            <el-radio :label="1" size="large">每天</el-radio>
            <el-radio :label="2" size="large">指定周期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.effectiveWeekType==2" prop="weekList">
          <el-checkbox-group v-model="ruleForm.weekList" disabled>
            <el-checkbox v-for="item in weekList" :key="item" :label="item.value">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="生效时段：" prop="effectivePeriodType">
          <el-radio-group v-model="ruleForm.effectivePeriodType" class="ml-4" disabled>
            <el-radio :label="1" size="large">全部时段</el-radio>
            <el-radio :label="2" size="large">指定时段</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.effectivePeriodType==2">
          <div style="display:flex;align-items: center;margin-bottom:10px;" v-for="(domain, index) in ruleForm.periodList"
            :key="index">
            <el-form-item
              :prop="'periodList.' + index + '.startTime'"
              :rules="{
                required: true,
                message: '请选择开始时间',
                trigger: 'blur',
              }"
            >
              <el-time-select
                v-model="domain.startTime"
                :max-time="endTime"
                class="mr-4"
                placeholder="开始时间"
                start="06:00"
                step="00:15"
                end="22:30"
                disabled
              />
            </el-form-item>
            <el-form-item
              :prop="'periodList.' + index + '.endTime'"
              :rules="{
                required: true,
                message: '请选择结束时间',
                trigger: 'blur',
              }"
              style="margin: 0 10px;"
            >
              <el-time-select
                v-model="domain.endTime"
                :min-time="domain.startTime"
                placeholder="结束时间"
                start="06:00"
                step="00:15"
                end="22:30"
                disabled
              />
            </el-form-item>
            <el-button type="danger" v-if="index!=0" size="small" icon="Delete" disabled circle @click.prevent="removeDomain(index)" />
            <el-button type="primary" v-else  size="small" icon="Plus" circle disabled @click.prevent="addDomain"/>
          </div>
          <!-- :disabled="ruleForm.periodList.length>2" -->
          <p style="color:#999;font-size:12px;">添加时段3/{{ ruleForm.periodList.length }}</p>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="使用周期：" prop="effectiveWeekType">
          <el-radio-group v-model="ruleForm.effectiveWeekType" class="ml-4" disabled>
            <el-radio :label="1" size="large">每天</el-radio>
            <el-radio :label="2" size="large">指定周期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.effectiveWeekType==2" prop="weekList">
          <el-checkbox-group v-model="ruleForm.weekList" disabled>
            <el-checkbox v-for="item in weekList" :key="item" :label="item.value">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="使用时段：" prop="effectivePeriodType">
          <el-radio-group v-model="ruleForm.effectivePeriodType" class="ml-4" disabled>
            <el-radio :label="1" size="large">全部时段</el-radio>
            <el-radio :label="2" size="large">指定时段</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.effectivePeriodType==2">
          <div style="display:flex;align-items: center;margin-bottom:10px;" v-for="(domain, index) in ruleForm.periodList"
            :key="index">
            <el-form-item
              :prop="'periodList.' + index + '.startTime'"
              :rules="{
                required: true,
                message: '请选择开始时间',
                trigger: 'blur',
              }"
            >
              <el-time-select
                v-model="domain.startTime"
                :max-time="endTime"
                class="mr-4"
                placeholder="开始时间"
                start="06:00"
                step="00:15"
                end="22:30"
                disabled
              />
            </el-form-item>
            <el-form-item
              :prop="'periodList.' + index + '.endTime'"
              :rules="{
                required: true,
                message: '请选择结束时间',
                trigger: 'blur',
              }"
              style="margin: 0 10px;"
            >
              <el-time-select
                v-model="domain.endTime"
                :min-time="domain.startTime"
                placeholder="结束时间"
                start="06:00"
                step="00:15"
                end="22:30"
                disabled
              />
            </el-form-item>
            <el-button type="danger" v-if="index!=0" size="small" icon="Delete" disabled circle @click.prevent="removeDomain(index)" />
            <el-button type="primary" v-else  size="small" icon="Plus" circle disabled @click.prevent="addDomain"/>
          </div>
          <p style="color:#999;font-size:12px;">添加时段3/{{ ruleForm.periodList.length }}</p>
        </el-form-item>
      </div>
      <el-form-item label="可用门店：" prop="shopPeriodType">
        <el-radio-group v-model="ruleForm.shopPeriodType" class="ml-4" disabled>
          <el-radio :label="1" size="large">全部门店</el-radio>
          <el-radio :label="2" size="large">指定门店</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.shopPeriodType==2" prop="shopIdList">
        <div class="opt-shopname">
          <el-button size="small" @click="handleChangeShop" style="margin-right:10px" disabled>选择门店</el-button>
          <template :key="item" v-for="item in currentShopName">
            <el-tag size="small" style="margin-right:10px">{{ item }}</el-tag>
          </template>
        </div>
      </el-form-item>

      <el-form-item label="使用规则限制：" prop="limit">
        <el-checkbox v-model="ruleForm.limit" label="可与其他优惠同享" disabled/>
        <p style="color:#999;font-size:12px;">优惠方式包括会员价、体验价、充值卡折扣（充值卡指定价）、折扣卡</p>
      </el-form-item>

      <el-form-item label="使用说明:">
        <el-input
          v-model="ruleForm.info"
          :rows="4"
          type="textarea"
          placeholder="输入使用说明"
        />
      </el-form-item>
      <h4>领取/购买规则</h4>
      <el-form-item :label="ruleForm.distributionType==2?'购买限制':'领取人群：'" prop="purchaseRestrictions">
        <el-radio-group v-model="ruleForm.purchaseRestrictions" class="ml-4" disabled>
          <el-radio :label="1" size="large">所有用户均可{{ ruleForm.distributionType==2?'购买':'领取 '}}</el-radio>
          <el-radio :label="2" size="large">仅新客可{{ ruleForm.distributionType==2?'购买':'领取 '}}</el-radio>
          <el-radio :label="3" size="large">指定客户可{{ ruleForm.distributionType==2?'购买':'领取 '}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="ruleForm.distributionType==2?'每人限购：':'每人限领：'" prop="claimRestrictions">
        <el-select
          v-model="ruleForm.claimRestrictions"
          class="m-2"
          placeholder="Select"
          size="large"
          disabled
        >
          <el-option
            v-for="item in limitList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.claimRestrictions != 1"
        prop="limitNumber"
        :label="ruleForm.distributionType==2?'限购数量：':'限领数量：'"
      >
        <el-input-number disabled v-model="ruleForm.limitNumber" :min="1" :precision="0" :controls="false" style="margin-right:10px" />张
      </el-form-item>
      <h4>裂变规则设置</h4>
      <el-form-item label="兑换券是否允许赠送给好友领取：" prop="canGift" label-width="240px">
        <el-switch v-model="ruleForm.canGift" />
      </el-form-item>
      <el-form-item label="失效时间：" prop="failureDuration" :rules="[
        { required: ruleForm.canGift, message: '请输入', trigger: 'blur' },
      ]">
        从赠送好友后<el-input-number v-model="ruleForm.failureDuration" :min="0" :precision="0" :controls="false" style="margin:0 10px;width: 100px;" data-unit="h"  class="my-el-input-number"/>，未领取则赠送失效
      </el-form-item>
      <el-form-item label="背景图片：" prop="backgroundPicture" :rules="[
        { required: ruleForm.canGift, message: '请上传图片', trigger: 'blur' },
      ]">
        <upload-img :file-list="backgroundPictureFileList" :limit="1" @handleUploadSuccess="handleImgUploadSuccess1"
          @handleUploadRemove="handleUploadRemove1" @handleUploadEmpty="handleUploadRemove1"></upload-img>
        <div class="tips">建议尺寸：800*800像，最多上传1张</div>
      </el-form-item>
      <el-form-item label="兑换券是否允许分享给好友领取：" prop="canShare" label-width="240px">
        <el-switch v-model="ruleForm.canShare" />
      </el-form-item>
      <el-form-item label="分享裂变：" prop="receiveCount" :rules="[
        { required: ruleForm.canShare, message: '请输入', trigger: 'blur' },
      ]">
        分享可裂变成<el-input-number v-model="ruleForm.receiveCount" :min="0" :precision="0" :controls="false" style="margin:0 10px;width: 100px;"/>张，供好友领取
      </el-form-item>
      <el-form-item label="领取背景图：" prop="receiveBackgroundPicture" :rules="[
        { required: ruleForm.canShare, message: '请上传图片', trigger: 'blur' },
      ]">
        <upload-img :file-list="receiveBackgroundFileList" :limit="1" @handleUploadSuccess="handleImgUploadSuccess2"
          @handleUploadRemove="handleUploadRemove2" @handleUploadEmpty="handleUploadRemove2"></upload-img>
        <div class="tips">建议尺寸：375*812像素，最多上传1张</div>
      </el-form-item>
      <el-form-item label="分享背景图：" prop="shareBackgroundPicture" :rules="[
        { required: ruleForm.canShare, message: '请上传图片', trigger: 'blur' },
      ]">
        <upload-img :file-list="shareBackgroundPictureFileList" :limit="1" @handleUploadSuccess="handleImgUploadSuccess3"
          @handleUploadRemove="handleUploadRemove3"  @handleUploadEmpty="handleUploadRemove3"></upload-img>
        <div class="tips">建议尺寸：375*812像素，最多上传1张</div>
      </el-form-item>

      <el-form-item label="">
        <div class="save-btn">
          <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
    <shopListDialog :dialogVisible="shopListDialogVisible" @changeDialogVisible="changeDialogVisible" :chooseList="checkList" @changeOptShop="changeOptShop"></shopListDialog>
    <serveListDialog :dialogVisible="serveListDialogVisible" @changeDialogVisibleServe="changeDialogVisibleServe" :chooseList="checkServeList" @changeOptServe="changeOptServe"></serveListDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref ,computed,watch} from 'vue'
import { rules,limitList,weekList } from '../../config/index.js'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import {COMPANY_ID} from  '@/common/constant.js'
import shopListDialog from '../../../add/cpns/shopListDialog/index';
import serveListDialog from '../../../add/cpns/serveListDialog/index';
import { getTeacherRankServeList} from '@/service/main/reserve'
import { getShopDownList } from '@/service/main/shop'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'

export default defineComponent({
  props: {
    activeType:{
      type: Number,
      default: 1
    },
    couponData:{
      type: Object,
      default: {}
    }
  },
  components: {
    shopListDialog,
    serveListDialog,
    UploadImg
  },
  emits:['submitForm'],
  setup(props,{emit}) {
    const store = useStore()
    const couponType = computed(() => props.activeType)
    const couponDataForm = computed(() => props.couponData)
    const backgroundPictureFileList = ref([])
    const receiveBackgroundFileList = ref([])
    const shareBackgroundPictureFileList = ref([])
    console.log(couponDataForm);
    const defaultTime = ref([
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(2000, 2, 1,23, 59, 59),
    ]) // '12:00:00', '08:00:00'
    // watch(() => couponType, (newValue) => {
    //   couponType.value = newValue
    //   if (couponType.value==3) {
    //     ruleForm.distributionType=2
    //     ruleForm.applicableScope=2
    //   }else{
    //     ruleForm.distributionType=1
    //     ruleForm.applicableScope=1
    //   }
    // }, {
    //   deep: true
    // })
    watch(() => couponDataForm.value, async(newValue) => {
      if (newValue) {
        ruleForm.name=newValue.name
        ruleForm.discount=newValue.discount
        ruleForm.distributionType=newValue.distributionType
        ruleForm.salePrice=newValue.salePrice
        ruleForm.saleDate=[newValue.saleStartDate,newValue.saleEndDate]
        ruleForm.saleStartDate = newValue.saleStartDate
        ruleForm.saleEndDate = newValue.saleEndDate
        ruleForm.total=newValue.total
        ruleForm.hasThreshold=newValue.hasThreshold
        ruleForm.thresholdPrice=newValue.thresholdPrice
        ruleForm.applicableScope=newValue.applicableScope
        ruleForm.serveIdList=newValue.serveIdList
        checkServeList.value=newValue.serveIdList
        ruleForm.validityType=newValue.validityType
        ruleForm.date=[newValue.startDate,newValue.endDate]
        ruleForm.startDate = newValue.startDate
        ruleForm.endDate = newValue.endDate
        ruleForm.days=newValue.days
        ruleForm.effectivePeriodType=newValue.effectivePeriodType
        ruleForm.periodList=newValue.periodList
        ruleForm.shopPeriodType=newValue.shopPeriodType
        ruleForm.shopIdList=newValue.shopIdList
        checkList.value=newValue.shopIdList
        ruleForm.limit=newValue.limit
        ruleForm.info=newValue.info
        ruleForm.purchaseRestrictions=newValue.purchaseRestrictions
        ruleForm.claimRestrictions=newValue.claimRestrictions
        ruleForm.limitNumber=newValue.limitNumber
        ruleForm.weekList=newValue.weekList
        ruleForm.effectiveWeekType=newValue.effectiveWeekType
        ruleForm.canGift=newValue.canGift
        ruleForm.failureDuration=newValue.failureDuration
        ruleForm.backgroundPicture=newValue.backgroundPicture
        ruleForm.canShare=newValue.canShare
        ruleForm.receiveCount=newValue.receiveCount
        ruleForm.receiveBackgroundPicture=newValue.receiveBackgroundPicture
        ruleForm.shareBackgroundPicture=newValue.shareBackgroundPicture
        ruleForm.isLimit = newValue.isLimit
        ruleForm.limitApplet = newValue.limitApplet
        backgroundPictureFileList.value = [{url:newValue.backgroundPicture}]
        receiveBackgroundFileList.value = [{url:newValue.receiveBackgroundPicture}]
        shareBackgroundPictureFileList.value = [{url:newValue.shareBackgroundPicture}]
      }
    }, {
      deep: true
    })

    const ruleForm = reactive({
      name: "",
      discount: "",
      distributionType:1,
      isLimit:false,
      limitApplet:false,
      salePrice: "",
      saleDate:null,
      total:"",
      hasThreshold: false,
      thresholdPrice: "0",
      applicableScope:1,
      serveIdList:[],
      validityType: "",
      days: '',
      date: '',
      effectivePeriodType:1,
      periodList:[{
        startTime:null,
        endTime:null
      }],
      shopPeriodType:1,
      shopIdList:[],
      limit:true,
      info: "",
      purchaseRestrictions:1,
      claimRestrictions:1,
      limitNumber:"",
      weekList:[],
      effectiveWeekType:1,
      canGift:false,
      failureDuration:'',
      backgroundPicture:null,
      canShare:false,
      receiveCount:'',
      receiveBackgroundPicture:null,
      shareBackgroundPicture:null
    })
    const shopListDialogVisible = ref(false)
    const serveListDialogVisible = ref(false)
    const checkList=ref([])
    const checkServeList=ref([])

    const filterArray = (array1,array2)=>{
      let array = []
      array = array1.filter(item => {
        let arrList = array2.map(it => it)
        return arrList.includes(item.serveId)
      })
      return array
    }
    
    const filterArrayShop = (array1,array2)=>{
      let array = []
      console.log(array1,array2);
      array = array1.filter(item => {
        let arrList = array2.map(it => it)
        return arrList.includes(item.id)
      })
      return array
    }

    const teacherServeList = ref([])
    const optDataList = ref([])
    const initPage = async () => {
    //获取服务数据
      let data = await getTeacherRankServeList({enabled:true})
      const res = await getShopDownList()
      let shoplist = res.data
      shoplist.unshift({
        shopId:0,
        shopName:"总部"
      })
      optDataList.value=shoplist
      let list = data.data
      let newList = {kindName:'所有服务',kindId:0,infos:[]}

      list.forEach((ele, index) => {
        let paralist = ele.infos
        newList.infos.push(...paralist)
      })
      let teacherServeInfoLists=[newList,...data.data]
      teacherServeList.value=teacherServeInfoLists[0].infos
      let resShopName=await filterArrayShop(optDataList.value,ruleForm.shopIdList)
      currentShopName.value=resShopName.map(item=>{
        return item.shopName
      })
      let resServeName=await filterArray(teacherServeList.value,ruleForm.serveIdList)
      currentServeName.value=resServeName.map(item=>{
        return item.serveName
      })
    }
    initPage()

    const handleDateChange = value => {
      ruleForm.startDate = value[0]
      ruleForm.endDate = value[1]
    }
    const handleSaleDateChange = value => {
      ruleForm.saleStartDate = value[0]
      ruleForm.saleEndDate = value[1]
    }

    const ruleFormRef = ref()

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          emit('submitForm',ruleForm)
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const addDomain = () => {
      ruleForm.periodList.push({
        startTime:null,
        endTime:null
      })
    }
    const removeDomain = (index) => {
      ruleForm.periodList.splice(index, 1)
    }
    const handleChangeShop = () =>{
      shopListDialogVisible.value=true
    }
    const handleChangeServe = () =>{
      serveListDialogVisible.value=true
    }
    const changeDialogVisible = flag => {
      shopListDialogVisible.value = flag
    }
    const changeDialogVisibleServe = flag => {
      serveListDialogVisible.value = flag
    }
    const currentShopName = ref([])
    const changeOptShop = (obj) => {
      ruleForm.shopIdList = obj.ids;
      currentShopName.value = obj.optNameArr;
    }
    const currentServeName = ref([])
    const changeOptServe = (obj) => {
      ruleForm.serveIdList = obj.ids;
      currentServeName.value = obj.optNameArr;
    }
    const handleImgUploadSuccess1 = (res) =>{
      ruleForm.backgroundPicture = res.response.data.fileName
    }
    const handleImgUploadSuccess2 = (res) =>{
      ruleForm.receiveBackgroundPicture = res.response.data.fileName
    }
    const handleImgUploadSuccess3 = (res) =>{
      ruleForm.shareBackgroundPicture = res.response.data.fileName
    }
    const handleUploadRemove1 = () => {
      ruleForm.backgroundPicture = ''
      backgroundPictureFileList.value = []
    }
    const handleUploadRemove2 = () => {
      ruleForm.receiveBackgroundPicture = ''
      receiveBackgroundFileList.value = []
    }
    const handleUploadRemove3 = () => {
      console.log("1111");
      ruleForm.shareBackgroundPicture = ''
      shareBackgroundPictureFileList.value = []
    }


    return {
      ruleFormRef,
      rules,
      ruleForm,
      handleSaveClick,
      handleDateChange,
      handleSaleDateChange,
      weekList,
      shopListDialogVisible,
      serveListDialogVisible,
      limitList,
      addDomain,
      removeDomain,
      handleChangeShop,
      changeDialogVisible,
      checkList,
      checkServeList,
      changeOptShop,
      changeOptServe,
      currentShopName,
      handleChangeServe,
      changeDialogVisibleServe,
      currentServeName,
      couponType,
      couponDataForm,
      filterArray,
      filterArrayShop,
      teacherServeList,
      optDataList,
      defaultTime,
      backgroundPictureFileList,
      receiveBackgroundFileList,
      shareBackgroundPictureFileList,
      handleImgUploadSuccess1,
      handleImgUploadSuccess2,
      handleImgUploadSuccess3,
      handleUploadRemove1,
      handleUploadRemove2,
      handleUploadRemove3
    }

  }
})
</script>

<style scoped lang="less">
.base-form {
  .validity-period-wrap {
    &:deep(.el-radio-group) {
      display: flex;
      flex-direction: column;

      .el-radio {
        margin-bottom: 16px;
      }
    }
  }

  .radio-input {
    span {
      display: inline-block;
      margin: 0 6px;
    }

    &:deep(.el-input) {
      max-width: 100px !important;
    }
  }

  &:deep(.el-form) {
    max-width: 800px;
  }
  h4{
    margin: 20px 0 30px;
  }
  &:deep(.el-input-number .el-input__inner){
    text-align: left;
  }
  .my-el-input-number {
    position: relative;
  }
  .my-el-input-number::after {
    content: 'h';
    display: inline-block;
    height: 20px;
    line-height: 20px;
    width: 20px;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
  }
}
</style>
